<template>
  <div class="bg-white registration-wrapper">
    <div class="px-3 py-2 flex flex-col h-full w-full overflow-auto">
      <header class="app">
          <div
            class="store-logo-container flex justify-center items-center max-h-14 h-14 bg-white"
          >
            <NavigationStoreLogo />
          </div>
      </header>
      <VeeForm
        @invalid-submit="showErrorInput()"
        @submit="registerProcess($event)"
        ref="registerForm"
        class="app-registration flex flex-col gap-4"
      >
        <div class="font-bold text-2xl py-4 border-b">Erstelle dein expert Konto</div>
        <div class="text-xs">
          Wenn Sie sich anmelden, können Sie den Bezahlprozess schneller durchlaufen,
          mehrere Lieferadressen speichern und Ihre Daten bei Bedarf bequem anpassen.
          <br />
          <br />
          Felder mit einem * müssen ausgefüllt werden
        </div>
        <div class="flex flex-col gap-4 relative">
          <div>
            <div class="grid grid-cols-1 gap-4">
              <div>
                <label class="text-xs py-2 block">Anrede*</label>
                <div>
                  <CheckoutVeeFieldOrderInvoiceSalutation />
                </div>
              </div>
              <div>
                <template v-if="salutations == 'COMPANY'">
                  <label class="text-xs py-2 block">Firmenname*</label>
                  <div>
                    <CheckoutVeeFieldOrderInvoiceCompany />
                  </div>
                </template>
              </div>
              <div>
                <label class="text-xs py-2 block">Vorname*</label>
                <CheckoutVeeFieldOrderInvoiceFirstName />
              </div>
              <div>
                <label class="text-xs py-2 block">Nachname*</label>
                <CheckoutVeeFieldOrderInvoiceLastName />
              </div>
            </div>
            <div v-if="addressAutomatic">
              <div class="py-4">Beginnen Sie bei der Adresseingabe mit der Straße</div>
              <div
                @click="addressAutomatic = false"
                class="font-bold py-4 cursor-pointer"
              >
                > Adresse manuell eingeben
              </div>
            </div>

            <div v-if="!addressAutomatic" class="grid grid-cols-1 lg:grid-cols-3 gap-2">
              <div
                @click="addressAutomatic = true"
                v-if="false"
                class="col-span-3 py-4 font-bold cursor-pointer"
              >
                > Adresseingabe mit Autocomplete
              </div>
              <div class="col-span-2">
                <label class="text-xs py-2 block">Straße*</label>
                <CheckoutVeeFieldOrderInvoiceStreet />
              </div>
              <div class="col-span-2">
                <label class="text-xs py-2 block">Hausnr.*</label>
                <CheckoutVeeFieldOrderInvoiceHouseNumber />
              </div>
              <div class="col-span-2">
                <label class="text-xs py-2 block">PLZ*</label>
                <CheckoutVeeFieldOrderInvoiceZip />
              </div>
              <div class="col-span-2">
                <label class="text-xs py-2 block">Stadt*</label>
                <CheckoutVeeFieldOrderInvoiceCity />
              </div>
              <div class="col-span-2">
                <label class="text-xs py-2 block">Land*</label>
                <CheckoutVeeFieldOrderInvoiceCountry />
              </div>
            </div>
          </div>
          <div class="text-xs w-full overflow-auto" v-html="debug" v-if="useUserGroup()?.value == 'internal'" />
          <div class="text-xs">
            Die Rechnungsadresse muss sich in Deutschland befinden. Für EU-Bestellungen
            nutzen Sie bitte das Kontaktformular oder kontaktieren Sie unseren
            Kundenservice unter der 0800 0506212 (kostenlos aus dem deutschen Fest- und
            Mobilfunknetz).
          </div>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <CheckoutPhone :bins="bins" />
          </div>
          <div>
            <label class="text-xs py-2 block">Geburtsdatum*</label>
            <VeeField
              name="date"
              rules="required"
              v-model="useCartFormData().value.invoiceAddress.date"
              v-slot="{ field, meta, errors }"
            >
              <div
                class="option-included"
                :class="meta.touched ? (meta.valid ? 'valid' : 'invalid') : ''"
              ><!-- 2018-12-31-->
                <input
                  v-bind="field"
                  class="date-input inp sm w-full"
                  type="date"
                  :max="getToday()"
                  placeholder="Geburtsdatum.*"
                />
              </div>
              <span>{{ errors[0] }}</span>
            </VeeField>
          </div>
        </div>
        <div>
          <div class="grid grid-cols-1 gap-4">
            <div>
              <label class="text-xs py-2 block">E-Mail-Adresse*</label>
              <div>
                <CheckoutVeeFieldOrderInvoiceMail />
              </div>
            </div>
            <div>
              <label class="text-xs py-2 block">E-Mail-Adresse wiederholen*</label>
              <div>
                <VeeField name="repeatEmail" :rules="'required|equal:' + useCartFormData().value.invoiceAddress.email + ',E-Mail'" v-model="repeatEmail" v-slot="{field, meta, errors}">
                    <div :class="meta.touched ? (meta.valid) ? 'valid' : 'invalid' : ''" >
                        <input v-bind="field" class="inp sm" type="email" placeholder="E-mail wiederholen*" />
                    </div>
                    <span>{{ errors[0] }}</span>
                </VeeField>
              </div>
            </div>
            <div>
              <label class="text-xs py-2 block">Passwort*</label>
              <div>
                <CheckoutVeeFieldOrderInvoicePassword />
              </div>
            </div>
            <div>
              <label class="text-xs py-2 block">Passwort wiederholen*</label>
              <div>
                <VeeField name="passwordRepeat" :rules="'required|equal:' + useCartFormData().value.registration.login.password + ',Passwort'" v-model="repeatPassword" v-slot="{field, meta, errors}">
                    <div :class="meta.touched ? (meta.valid) ? 'valid' : 'invalid' : ''" >
                        <input v-bind="field" class="inp sm" type="password" placeholder="Passwort wiederholen*" />
                    </div>
                    <span>{{ errors[0] }}</span>
                </VeeField>
              </div>
            </div>
            <div
              class="text-sm p-3 border border-dashed border-[#8dccea] bg-[#b4e7ff] text-[#0078b1]"
            >
            <CheckoutVeeFieldPasswordRequirementInfo />
            </div>
          </div>
        </div>
        <CheckoutVeeFieldWarranty :bins="bins" />
        <div class="p-3 border text-sm">
          <CheckoutTerms />
        </div>
        <BasicCaptcha class="captchaElement" ref="recaptcha" />
        <div
          v-if="result != ''"
          class="text-error py-2 px-2 text-center border border-error border-dashed bg-error-light"
          v-text="result"
        />
        <button class="btn-primary font-bold cursor-pointer" type="submit">Registrieren</button>
        <button class="btn-button text-sm" @click="emit('backToLogin')">Zurück</button>
      </VeeForm>
    </div>
  </div>
</template>
<script setup>
import { v4 as uuidv4 } from "uuid";
import { CHECKOUT_ERRORS } from "../Checkout/Errors";
import { useCurrentUserState } from "~/composables/states";
import { getExpUserProfile } from "~/composables/ExpApi/expUser";
const props = defineProps({
  orderProcess: {
    type: Object,
  },
  bins: {
    type: Object,
  },
});

const registerForm = ref(null);
const debug = ref('');
const repeatEmail = ref("");
const repeatPassword = ref("");

const identicalEmail = ref();
const identicalPassword = ref();
var addressAutomatic = ref(false);
var salutations = ref("default");

function salutationChange(target) {
  salutations.value = target;
}
watch(repeatEmail, (input) => {
  if (input == registerForm.value.values.email) {
    identicalEmail.value = true;
  } else {
    identicalEmail.value = false;
  }
});

watch(repeatPassword, (input) => {
  if (input == registerForm.value.values.password) {
    identicalPassword.value = true;
  } else {
    identicalPassword.value = false;
  }
});

if (!useCurrentUserState().value?.defaultAddress) {
  await getExpUserProfile();
}

const recaptcha = ref(null);

var modal = ref({
  DataSecurityInfo: false,
});
function getToday() {
  let tempDate = new Date();
  return tempDate.getFullYear() + '-' + ('0' + (tempDate.getMonth()+1)).slice(-2) + '-' + ('0' + (tempDate.getDate()+1)).slice(-2)
}
async function register(csrfToken, registration, captcha) {

  console.debug("Function Register - Execute", {
    "csrfToken": csrfToken,
    "registration": registration,
    "captcha": captcha
  });
  
  return await useFetch("/api/neo/frontend/_api/user/register", {
    headers: {
      "bt-use-user-auth": "true",
      "csrf-token": csrfToken,
    },
    method: "POST",
    body: {
      registration: registration,
      captcha: captcha,
    },
  });
}

function showErrorInput(){
  let firstError = document.body.querySelector(".app-registration .invalid")
  if (process.client) {
    window.scrollTo(0, firstError.offsetTop);
  }
}

const result = ref("");
async function registerProcess(event) {
  console.debug("registerProcess Start:",event);
  
  let captcha = document.body.querySelector(".app-registration .g-recaptcha-response").value;
  let captchaElement = document.body.querySelector(".captchaElement");
  captchaElement?.classList.remove("border-[#a94442]");
  captchaElement?.classList.remove("border-2");
  console.debug("RegisterProcess - Captcha", captcha);
  debug.value = "csrfToken:" + useCurrentUserState().value?.csrfToken + "<br/><br/>  FormSample:" + event.title + event.firstName + event.lastName + "<br/><br/> captcha:" + captcha;
  if (!captcha) {
    captchaElement?.classList.add("border-[#a94442]");
    captchaElement?.classList.add("border-2");
  } else {
    if (identicalEmail.value && identicalPassword.value) {
      var id = uuidv4();
      var registration = {
        invoiceAddress: {
          id: id,
          title: event.title,
          firstName: event.firstName,
          lastName: event.lastName,
          street: event.street,
          zip: event.zip,
          city: event.city,
          countryCode: "DE",
          company: event.companyName ? null : event.companyName,
          houseNumber: event.houseNumber,
          date: event.date,
          addition: null,
          email: event.email,
          fax: null,
          mobile: null,
          telephone: event.telephone,
        },
        login: {
          email: event.email,
          password: event.password,
        },
      };
      console.debug("RegisterProcess - Data Format:", registration);
      
      let { error, data } = await register(useCurrentUserState().value?.csrfToken, registration, captcha);
      recaptcha.value.reset();
      let errorMessage = getErrorMessage(error);
      let res = "";
      if (errorMessage) {
        res = errorMessage;
        clearNuxtData(['userProfile','expUserProfile']);
        //reloadNuxtApp(); 
      } else {
        if (error?.value?.statusCode) {
          res = "Ein Fehler ist aufgetreten.";
          result.value = "Ein Fehler ist aufgetreten: " + error?.value?.statusCode;
        } else {
          res = "Sie wurden erfolgreich registriert.";
          result.value = "Sie wurden erfolgreich registriert.";
          emit("navigateModal", "NONE");
        }
        console.log("App Register", res);
      }
    }
  }
}

function getErrorMessage(error) {
  let parsedError = getError(error);
  if (parsedError?.errorcode)
    return CHECKOUT_ERRORS[parsedError?.namespace + "::" + parsedError?.errorcode];
  return false;
}

function getError(error) {
  if (typeof error?.value?.cause?.data == "object") return error?.value?.cause?.data;
  else if (error?.value?.data == "object") return error?.value?.data;
  else if (error?.value?.cause?.response?._data == "object")
    return error?.value?.cause?.response?._data;
  else {
    try {
      return JSON.parse(error?.value?.data);
    } catch (e) {
      return error?.value?.data;
    }
  }
}
const emit = defineEmits(["closeRegister", "navigateModal"]);
</script>
<style>
.invalid + span {
  @apply text-xs text-error;
}
.valid + span {
  @apply text-xs text-success;
}
.valid {
  position: relative;
  border: 1px solid green;
}

.valid:after {
  content: "";
  position: absolute;
  right: 10px;
  width: 1em;
  height: 1em;
  top: 50%;
  pointer-events: none;
  transform: translatey(-50%);
  background-color: green;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-image: url("../../assets/mediums/Icons/check-regular.svg");
  mask-image: url("../../assets/mediums/Icons/check-regular.svg");
}
select {
  @apply appearance-none;
}
.date-input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 38px;
}

.invalid {
  border: 1px solid red;
  position: relative;
}

.invalid:after {
  content: "";
  position: absolute;
  pointer-events: none;
  width: 1em;
  height: 1em;
  top: 50%;
  transform: translatey(-50%);
  right: 10px;
  background-color: red;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-image: url("../../assets/mediums/Icons/xmark-solid.svg");
  mask-image: url("../../assets/mediums/Icons/xmark-solid.svg");
}
.option-included:after,
.password:after {
  right: 40px;
}
</style>
