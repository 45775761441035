<template>
  <div>
    <NuxtPwaManifest />
    <ClientOnly>
      <AppLogin @close-login="dialog = 'NONE'" @open-register="dialog = 'REGISTER'" @back-to-intro="dialog = 'INTRO'"
        @navigate-modal="navigateAppModal" v-if="dialog == 'LOGIN'" />
      <AppIntro @close-intro="dialog = 'NONE'" @access-login="dialog = 'LOGIN'" @navigate-modal="navigateAppModal"
        v-if="dialog == 'INTRO'" />
      <AppStoreAssignment @close-dialog="dialog = 'NONE'" @navigate-modal="navigateAppModal"
        v-if="dialog == 'STOREFINDER'" />
      <AppRegister @close-intro="dialog = 'NONE'" @back-to-login="dialog = 'LOGIN'" @navigate-modal="navigateAppModal"
        v-if="dialog == 'REGISTER'" />
      <AppPasswordRecover @back-to-login="dialog = 'LOGIN'" @navigate-modal="navigateAppModal"
        v-if="dialog == 'PASSWORDRECOVER'" />
    </ClientOnly>
    <div v-if="dialog == 'NONE'">
      <LayoutAppHeader />
      <main class="app">
        <slot />
      </main>
      <BasicBtErrorBoundary>
        <BtDevOnly>
          <p>Version Info:</p>
          <pre>{{ useRuntimeConfig().public.version }}</pre>
          <p>Flagsmith State:
          <pre>
            {{ $flags?.getState() }}
          </pre>
          </p>
        </BtDevOnly>
      </BasicBtErrorBoundary>
      <BasicBtErrorBoundary>
        <LayoutAppFooter @navigate-modal="navigateAppModal" />
      </BasicBtErrorBoundary>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getExpUserProfile } from "~/composables/ExpApi/expUser";
import { useCurrentStore } from "~/composables/states";
const { $pwa } = useNuxtApp();

if ($pwa?.needRefresh || $pwa?.registrationError) {
  console.warn('updating service worker');
  await $pwa.updateServiceWorker(true);
}

const dialog = ref("NONE");

useHead({
  meta: [
    {
      id: "viewport",
      name: "viewport",
      content:
        "width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
    },
  ],
});
const currentTopicAmount: any = ref('0');

provide('currentTopicAmount', currentTopicAmount)

onNuxtReady(async () => {
  changeTopicAmount();
})

async function changeTopicAmount() {
  const { data: amount } = await useFetch("/api/nea/topic-service/api/topics/storeId/" + useCurrentStore()?.value?.id + "/count", {
    query: { active: true }
  });
  currentTopicAmount.value = amount;
}

watchEffect(async () => {
  if (useCurrentStore()?.value?.id != 'e_2879130') {
    dialog.value = "NONE";
    changeTopicAmount();
  } else {
    dialog.value = "STOREFINDER";
  }
})

onNuxtReady(() => {
  try {
    useNuxtApp()?.$flags?.setTrait("isAppUser", true);
  } catch (error) {
    console.error("could not set flagsmith trait", error);
  }
});

useAppConfig().homelink = "/app";
// useAppConfig().homelinkExternal = false;
useAppConfig().isAppUser = true;
function navigateAppModal(n: string) {
  dialog.value = n || "NONE";
}

</script>

<style>
main.app {
  padding-top: 135px;
  padding-bottom: 100px;
}
</style>
