<template>
  <div class="bg-white h-[100vh] w-full">
    <div class="p-2 flex flex-col h-full absolute bottom-0 w-full">
      <form @submit.prevent="signin()" class="flex flex-col gap-4">
        <header class="app">
          <div
            class="store-logo-container flex justify-center items-center max-h-14 h-14 bg-white"
          >
            <NavigationStoreLogo />
          </div>
        </header>
        <div class="text-2xl border-b font-bold py-2">Willkommen bei expert</div>
        <div class="flex flex-col gap-3">
          <div class="text-lg py-2 font-bold">Anmelden</div>
          <div class="text-sm">
            Wenn Sie bei uns ein Benutzerkonto besitzen, melden Sie sich bitte an
          </div>
          <div>
            <input v-model="email" class="inp mail" type="text" @focus="invalid = false" :class="invalid ? 'invalid':''" required placeholder="E-Mail-Adresse*" />
          </div>
          <div>
            <input v-model="pass" class="inp pass" type="password" @focus="invalid = false" :class="invalid ? 'invalid':''" required placeholder="Passwort*" />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 items-center justify-between">
          <div>
            <button type="submit" class="text-center btn-primary block w-full font-bold">
              Anmelden
            </button>
          </div>
          <div class="text-right font-bold">
            <div @click="$emit('navigateModal', 'PASSWORDRECOVER')" class="text-sm py-4 text-primary">
              Passwort vergessen?
            </div>
          </div>
        </div>
        <div class="text-error" :class="invalid ? '' : 'invisible'">Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut</div>
      </form>
      <div class="flex mt-4 flex-col gap-4">
        <div class="text-lg py-2 font-bold">Neu bei expert?</div>
        <div class="text-sm border-b pb-2">
          Registriere dich jetzt, um exklusive Coupons einsehen zu können!
        </div>
        <a class="text-center btn-primary block w-full font-bold" @click="$emit('openRegister')">Jetzt registrieren</a>
        <NuxtLink to="/app" class="block py-3 col-span-4 text-center text-xs" @click="$emit('navigateModal', 'NONE'); ">
          Zurück
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getExpUserProfile } from "~/composables/ExpApi/expUser";
const { ensurePermissions } = usePushNotificationUtils();

onNuxtReady(async () => {
  ensurePermissions('TRACKING');
})

const email = ref("");
const pass = ref("");
const emit = defineEmits(["closeLogin", "navigateModal"]);
const invalid = ref(false);
async function signin() {
  if (useCurrentUserState()?.value?.csrfToken == undefined) {
    await getExpUserProfile();
  }
  let data = null;
  try {
    data = await login(useCurrentUserState()?.value?.csrfToken, email, pass);
  } catch (error) {
    clearNuxtData(['userProfile','expUserProfile']);
    reloadNuxtApp(); 
    console.log(error);
    invalid.value = true;
    email.value = '';
    pass.value = '';
  }

  if(data){
    clearNuxtData('userProfile');
    await getExpUserProfile();
    if (data?.value) {
      if (useIsGuestCookie().value == false) {
        emit("navigateModal", "STOREFINDER");
      } else {
        emit("navigateModal", "STOREFINDER");
      }
    }
  }
}
</script>
